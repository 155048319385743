import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Layout from "../components/Layout/Layout"
import AccordionItem from "../components/AccordionItem/AccordionItem"
import useScreenSize from "../hooks/useScreenSize"
import { gTagScriptConsent } from "../components/Scripts/Gtm.Head";

const Recipe = ({ pageContext }) => {
    const { recipes, contentfulData, dataLayer } = pageContext || {}
    const { seoData, globalComponents, hero, labels } = contentfulData || {}

    const { contentLabels } = labels?.Labels || {}
    const windowSize = useScreenSize()
    const [columnCount, setColumnCount] = useState(null)

    useEffect(() => {
        const hash = window.location.hash || ''

        if (hash) {
            const timer = setTimeout(() => {
                const id = hash.split('#')[1]
                const gridActiveItem = document.querySelector(`[data-item="${id}"]`)
                if (gridActiveItem) {
                    gridActiveItem.querySelector('a').click()
                    gridActiveItem.scrollIntoView({behavior: "smooth", block: "start"})
                }
            }, 500)

            return () => clearTimeout(timer)
        }
    }, [])

    useEffect(() => {
        setColumnCount(windowSize.isDesktop ? 4 : 2)
    }, [windowSize.isDesktop])

    // GTM custom dataLayer Object based on environment
    if (typeof window !== "undefined") {
        const dataLayerObj = {
            ...dataLayer,
            page: {
                url: window.location.href,
                title: seoData?.title || seoData?.metaTitle,
            },
            content: {
                type: "Recipes page",
                category: "",
            },
        }
        window.gtmDataLayer = dataLayerObj
    }

    const renderGridItems = () => {
        const finalItems = []
        let columns = []
        const arrowPosition = windowSize.isDesktop ? [
            'before:left-[85%]',
            'before:left-[10%]',
            'before:left-[35%]',
            'before:left-[60%]'
        ] : [
            'before:left-[72%]',
            'before:left-[20%]',
        ]

        recipes.forEach((recipe, index) => {
            const recipeImg = recipe.PhotoURLAdditional.find(item => item.PhotoTypeID === 25)

            columns.push(
                <AccordionItem
                    key={`accordion-item-${recipe.RecipeID}`}
                    type="recipe"
                    index={index}
                    itemImage={recipeImg?.PhotoLink}
                    itemName={recipe.RecipeName}
                    item={recipe}
                    arrowPosition={arrowPosition[(index + 1) % columnCount]}
                    labels={contentLabels}
                />
            )

            if ((index + 1) % columnCount === 0) {
                finalItems.push(
                    <div
                        key={`accordion-row-${index + 1}`}
                        className="flex flex-wrap justify-between mb-[1%]">
                        {columns}
                    </div>
                )
                columns = []
            }
        })

        if (columns.length !== 0) {
            finalItems.push(
                <div key={`accordion-row-last`} className="recipes-last-row flex flex-wrap">
                    {columns}
                </div>
            )
            columns = []
        }

        return finalItems
    }

    return (
        <Layout seoData={seoData} globalComponents={globalComponents} hero={hero}>
            <div className="max-w-[1440px] p-[10px] grid--padding mx-auto">
                <h1 className="text-[40px] text-[#231f20] text-center leading-[45px] mt-[60px] mb-[25px]">
                    {contentLabels?.rezepteHeaderText}
                </h1>
                <div className="lg:flex lg:flex-wrap">
                    {renderGridItems()}
                </div>
            </div>
        </Layout>
    )
}

export const Head = () => {
    const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}

    return (
        <>
            <script
                id="first-unique-id"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `var MdlzdataLayer = ${JSON.stringify(
                        dataObject
                    )};`,
                }}
            />
            {process.env.GATSBY_ACTIVE_ENV === 'production' && <script
                async
                type="text/javascript"
                dangerouslySetInnerHTML={{__html: gTagScriptConsent}}
            />}
        </>
    )
}

Recipe.propTypes = {
    pageContext: PropTypes.shape({
        recipes: PropTypes.array,
        contentfulData: PropTypes.object,
        dataLayer: PropTypes.object,
    }),
}

export default Recipe
